@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../src/fonts/Montserrat-Regular.ttf");
}
* {
  font-family: "Montserrat" !important;
}

@layer components {
  .button {
    @apply rounded-full bg-white px-6 py-1 text-black font-semibold;
  }
  .circle {
    @apply rounded-full bg-white  text-black;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(
      78.27% 114.34% at 60.01% 112.56%,
      rgba(11, 11, 11, 0.6) 0%,
      rgba(89, 89, 89, 0) 100%
    ),
    radial-gradient(
      94.56% 94.56% at -1.44% 98.56%,
      rgba(168, 65, 249, 0.7) 0%,
      rgba(200, 81, 104, 0) 100%
    ),
    radial-gradient(
      105.23% 105.23% at 105.23% 8.69%,
      rgba(255, 87, 110, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #150135;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  background: #150135;
}

.Toastify__toast-theme--light {
  font-family: " Nixie One" !important;
  font-size: 1.2rem;
  padding: 0px !important;
  padding-left: 5px !important;
  margin: 0px !important;
  color: white !important;
}

.Toastify__toast {
  max-height: 80px !important;
  min-height: 50px !important;
  min-width: 60px !important;
  background-color: #150136 !important;
  color: white !important;
  width: 100%;
  font-weight: 900;
  font-size: 18px;
  padding-left: 20px !important;
}
/* .Toastify__progress-bar {
  display: none !important;
} */
